<!-- index -->
<template>
  <div class="navbar" v-if="!$root.isApp">
    <div class="nav-top">
      <router-link to="/" tag="div" class="nav-logo" />
      <div class="nav-menu">
        <el-menu
          :router="true"
          :default-active="routePath"
          text-color="#343537"
          active-text-color="#135697"
          mode="horizontal"
          @select="selectRoute"
        >
          <el-menu-item
            v-for="(item, i) in navList"
            router
            :key="i"
            :index="item.name"
          >
            {{ item.navItem }}
          </el-menu-item>

        </el-menu>
        <div class="user-info" v-if="userinfo">
          <div ><a :href="userUrl">会员中心</a></div>
          <div><span>{{ userinfo.fullname ||  userinfo.user_name}}</span>&nbsp;
            <el-tooltip class="item" effect="light" placement="bottom-start">
              <img src="../../assets/img/pc/v.png" alt="" />
              <div slot="content">成为认证会员，享受更多优惠，放舱更快捷。<a :href="authenticationUrl" v-if="isUserFn(userinfo.accounts_id)">去认证</a></div>
            </el-tooltip>
          </div>
          <div class="loginout" @click="loginoutFn">退出</div>
        </div>
        <div v-else class="user-info"><a :href="loginUrl">登录</a> <a :href="loginRegister">注册</a></div>
      </div>

    </div>
    <transition name="fade" mode="out-in">
      <!-- <div> -->
      <!-- <keep-alive> -->
      <router-view></router-view>
      <!-- </keep-alive> -->
    </transition>
  </div>
  <div v-else>
    <transition name="fade" mode="out-in">
      <!-- <div> -->
      <!-- <keep-alive> -->
      <router-view></router-view>
      <!-- </keep-alive> -->
    </transition>
  </div>
</template>

<script>
// 导入的其他文件 例如：import moduleName from 'modulePath';
import baseApi from '@/service/base'
import store from "../../store";
export default {
  name: "NavBar",
  //import所引入的组件注册
  components: {},

  data() {
    return {
      routePath: "/",
      navList: [
        { name: "/", navItem: "首页" },
        { name: "/about", navItem: "关于我们" },
        { name: "/recruit", navItem: "人才招聘" },
      ],
      userinfo: {},
      loginUrl:'',
      loginRegister:'',
      authenticationUrl:'',
      userUrl:''
    };
  },

  //监听属性
  computed: {
    isUserFn() {
      return function(val) {
        if(val ==  null || !val){
          return true
        }else if (parseInt(val) < 1){
          return true
        }
        return false;
      }
    },
  },

  //监控data中的数据变化
  watch: {
    $route(to, from) {
      console.log(to.path);
      this.routePath = to.path;
      // this.routePath = '/' + to.path.split('/')[1]
    },
  },

  //方法集合
  methods: {
    selectRoute(index, indexPath) {
      this.routePath = index;
    },
    toUrl(){
      let fromUrl  = window.origin+'/login';
      console.log(fromUrl,'fromUrlfromUrl');
      this.loginUrl = baseApi.sofreight_api+'/member_login.html?to='+fromUrl;
      this.userUrl  = baseApi.sofreight_api+'/member.html'
      this.loginRegister = baseApi.sofreight_api+'/member_register.html?to='+fromUrl;
      this.authenticationUrl = baseApi.sofreight_api+'/Identification.html?is_fba=1'
      console.log(this.loginUrl,this.userUrl,'this.loginUrlthis.loginUrl');
    },
    loginoutFn(){
      let token = localStorage.getItem("token") || '';
      this.$confirm('确认退出？')
          .then(_ => {
            this.$store.dispatch("logoutApiFn", {token:token}).then(res => {
              this.$router.go(0)
            })
          }).catch(_ => {});
    }
  },

  //创建完成 访问当前this实例
  created() {
    this.toUrl();
    this.userinfo = Object.keys(this.$store.state.user.info).length > 0 ? this.$store.state.user.info : '';
  },
  //挂载完成 访问DOM元素
  mounted() {
    this.routePath = this.$route.path;
    console.log(this.routePath);
  },
};
</script>
<style scoped lang="scss">
.navbar {
  width: 100%;
  min-width: 1194px;
  .nav-top {
    width: 60%;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav-logo {
      width: 246px;
      cursor: pointer;
      display: inline-block;
      height: 46px;
      background: url("../../assets/img/logo_fbahope.png") no-repeat;
      background-size: 100% 100%;
    }
    .nav-menu {
      display: flex;
      align-items: center;
      justify-items: center;
      .user-info{
        position: relative;
        margin-bottom: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-items: center;
        div{
          padding-right: 20px;
        }
        .loginout{
          position: absolute;
          right: 0;
          cursor: pointer;
          &:hover{
            color: #8cc5ff;
          }
        }
      }
      a{
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #343537;
        text-decoration: none;
        margin-right: 10px;
        &:hover{
          color: #8cc5ff;
        }
      }
    }
  }
}
/* 大屏幕（大桌面显示器，大于等于 1200px） */
$screen-lg-min: 1200px;
/* 中等屏幕（桌面显示器，大于等于 992px） */
$screen-md-min: 992px;
/* 小屏幕（平板，大于等于 768px） */
$screen-sm-min: 769px;

$screen-lg: 1366px;
@media (max-width: $screen-lg) {
  .navbar {
    .nav-top {
      width: 80%;
      margin: 0 auto;
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
<style lang="scss">
.el-menu.el-menu--horizontal {
  border: none;
}
.el-menu--horizontal > .el-menu-item.is-active {
  font-weight: 600;
  border: none;
}
.el-menu--horizontal > .el-menu-item {
  border: none;
}
.el-menu-item {
  font-size: 16px !important;
}
.el-menu--horizontal > .el-menu-item {
  height: 52px;
  line-height: 52px;
}
</style>